function Product({product}) {
    return (
        <div className="product">
            <a href={product.url} target="_vinted">
                <div className="title">{product.title}</div>
                <div className="thumb"><img src={product.photos[0].thumbnails[1].url} height="140" /></div>
                <div className="price">{product.price_numeric}€</div>
            </a>
        </div>
    );
}

export default Product;
