class Products {
    static getAll(size, catalog) {
        return new Promise((resolve, reject) => {
            const items = [];
            const allPromise = [];
            for (let i = 1; i < 6; i++) {
                allPromise.push(import('../config/item-p' + i + '.json'));
            }

            size = parseInt(size);
            catalog = parseInt(catalog);

            Promise.all(allPromise).then(itemsP => {
                for (let i in itemsP) {
                    for (let j in itemsP[i].items) {
                        if ((size === 0 || parseInt(itemsP[i].items[j].size_id) === size) && (catalog === 0 || parseInt(itemsP[i].items[j].catalog_id) === catalog)) {
                            items.push(itemsP[i].items[j]);
                        }
                    }
                }

                resolve(items);
            }).catch(err => {})
        });
    }

    static getFilters() {
        return new Promise((resolve, reject) => {
            this.getAll(0, 0).then(products => {
                const filters = {catalog: {}, size: {}};
                for (let i in products) {
                    filters.catalog[products[i].catalog_id] = products[i].catalog_branch_title;
                    filters.size[products[i].size_id] = products[i].size;
                }

                resolve(filters);
            })
        });
    }
}

export default Products;