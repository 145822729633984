import { useState, useEffect } from 'react';
import './App.css';
import Products from './libraries/Products'
import Product from './blocs/Product'

function App() {

    const [products, setProducts] = useState([]);
    const [filters, setFilters] = useState({});
    const [size, setSize] = useState(0);
    const [catalog, setCatalog] = useState(0);
    const [find, setFind] = useState(true);

    useEffect(() => {
        if (find) {
            Promise.all([Products.getAll(size, catalog), Products.getFilters()]).then((data) => {
                setFind(false);
                setProducts(data[0]);
                setFilters(data[1]);
            });
        }
    });

    if (products.length === 0) {
        return <div className="loading"><div className="loader"></div></div>;
    }

    const productsHtml = [];
    for (const i in products) {
        productsHtml.push(<Product key={"product-" + i} product={products[i]} />);
    }

    const filterSize = [];
    for (const i in filters.size) {
        filterSize.push(<option key={"size-" + i} value={i}>{filters.size[i]}</option>);
    }

    const filterCatlog = [];
    for (const i in filters.catalog) {
        filterCatlog.push(<option key={"catalog-" + i} value={i}>{filters.catalog[i]}</option>);
    }

    return (
        <div className="vinted">
            <header>
                <div className="bloc">{products.length} produits</div>
                <div className="bloc"><select name="size" onChange={(elem) => {setFind(true); setSize(elem.target.value)}}><option value="">Toutes les tailles</option>{filterSize}</select></div>
                <div className="bloc"><select name="catalog" onChange={(elem) => {setFind(true); setCatalog(elem.target.value)}}><option value="">Toutes les types</option>{filterCatlog}</select></div>
            </header>
            <div className="products">
                {productsHtml}
            </div>
        </div>
    );
}

export default App;
